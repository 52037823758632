<template>
  <div>
    <el-container>
        <el-header class="MHeader" v-show="isfullScreen">
            <div class="BackToMP" @click="BackToMP"><i class="el-icon-arrow-left"></i>文章管理</div>
            <div class="ArticleTitle">
                <el-input
                type="text"
                placeholder="请输入文章标题（5~1000个字）"
                v-model="TitleText"
                maxlength="1000"
                show-word-limit
                class="InputArea"
                >
                </el-input>
            </div>
            <div class="btn-Publish" @click="PublishBlog">发布文章</div>
            <div class="UserAvatar">
                <img :src="userinfo.icon">
                <div class="UserPanel">
                    <div class="ToMP" @click="BackToMP">博文管理</div>
                    <div class="UserCenter" @click="BackToUserCenter">个人中心</div>
                </div>
            </div>
        </el-header>
        <el-main>
            <div class="TagContent">
                <div class="TagTitle"><i class="el-icon-paperclip"></i>添加文章标签</div>
                <div class="TagBody">
                    <div class="TagContainer">
                        <div class="Tag" v-for="(item,index) in dynamicTags" :key="index">
                            {{item}}
                            <span @click="CloseTag(index)" class=""><i class="el-icon-close CloseMark"></i></span>
                        </div>
                    </div>
                    <div v-if="inputVisible" class="TagInput">
                        <el-input
                        class="input-new-tag "
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                        >
                        </el-input>
                    </div>
                    <div v-else class="TagAddBtn">
                        <el-button  size="small" class="btn-Add" @click="AddTag">添加</el-button>
                    </div>
                    <div class="IsPublish">
                        <el-select v-model="ispublishtext" placeholder="请选择" size="mini" class="PublishSelect" @change="SelectOvert">
                            <el-option
                            v-for="item in publishoption"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </div>
            <div class="Content">
                <mavon-editor 
                v-model="HtmlText"
                :toolbars="toolbars"
                ref="md"
                @change="change" 
                @imgAdd="imgAdd"
                @imgDel="imgDel"
                @save="saveMavon"
                @fullScreen="fullScreen"
                style="min-height: 500px"
                :ishljs = "true"/>
            </div>
            
        </el-main>
    </el-container>
    
  </div>
</template>

<script>
import { mavonEditor } from 'mavon-editor'
import 'mavon-editor/dist/css/index.css'
import {UploadLocalImage} from "@/api/user.js"
import {GetUserNickName} from "@/api/user.js"
import { PostBlog } from '../../api/blog.js'
// import '../../assets/rem.js'
export default {
    name:'Md_Article',
    components:{
        mavonEditor,
    },
    data(){
        return{
            toolbars:{
                bold: true, // 粗体
                italic: true, // 斜体
                header: true, // 标题
                underline: true, // 下划线
                strikethrough: true, // 中划线
                mark: true, // 标记
                superscript: true, // 上角标
                subscript: true, // 下角标
                quote: true, // 引用
                ol: true, // 有序列表
                ul: true, // 无序列表
                link: true, // 链接
                imagelink: true, // 图片链接
                code: true, // code
                table: true, // 表格
                fullscreen: true, // 全屏编辑
                readmodel: true, // 沉浸式阅读
                htmlcode: true, // 展示html源码
                help: true, // 帮助
                /* 1.3.5 */
                undo: true, // 上一步
                redo: true, // 下一步
                trash: true, // 清空
                save: true, // 保存（触发events中的save事件）
                /* 1.4.2 */
                navigation: true, // 导航目录
                /* 2.1.8 */
                alignleft: false, // 左对齐
                aligncenter: false, // 居中
                alignright: false, // 右对齐
                /* 2.2.1 */
                subfield: true, // 单双栏模式
                preview: true, // 预览
            },
            userinfo:'',
            
            
            inputVisible:false,
            inputValue:'',

            dynamicTags:[],
            TitleText:'',
            HtmlText:'',
            isfullScreen:true,
            mdData:'',

            ispublishtext:'他人可见',
            publishoption:[
                {
                value: '0',
                label: '他人可见'
                }, {
                value: '1',
                label: '他人不可见'
                }
            ],
            overt:0,

            leavemark:false
        }
    },
    created(){
        GetUserNickName().then(res=>{
            this.userinfo = res.data
        })
    },
    mounted(){
        let _this = this
        window.onbeforeunload = function (e) {
        if (_this.$route.name == "Md_Article") {
            e = e || window.event;
            if (e) {
            e.returnValue = '正在离开本页面，本页面内所有未保存数据都会丢失';
            }
            return '正在离开本页面，本页面内所有未保存数据都会丢失';
        } else {
            window.onbeforeunload = null
        }
    };

    },
    beforeRouteLeave(to, from, next) {
        if(this.leavemark){
            next()
        }else{
            this.$confirm('正在离开本页面，本页面内所有未保存数据都会丢失，是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                next()
            }).catch(() => {
                return         
            });
        } 
    },
    methods:{
        PublishBlog(){
            if(this.TitleText == ''){
                this.$message.error('请填写文章标题！');
            }
            else if(this.dynamicTags.length == 0){
                this.$message.error('请添加文章标签！');
            }
            else if(this.HtmlText == ''){
                this.$message.error('请填写文章内容！');
            }
            else{
                let blogData = {
                    "content":this.mdData,
                    "title":this.TitleText,
                    "keywords":this.dynamicTags,
                    "overt":this.overt
                }
                PostBlog(blogData).then(res=>{
                    this.$message({message: '发布成功，即将跳转...',type: 'success'});
                    setTimeout(()=>{
                        this.leavemark = true
                        this.$router.push({path:'/mp_blog'})
                    },2000)
                })
            }
        },
        SelectOvert(overtmark){
            this.overt = overtmark
            console.log(overtmark)
            console.log(this.overt)
        },
        BackToMP(){
            this.$router.push({path:'/mp_blog'})
        },
        BackToUserCenter(){
            this.$router.push({path:'/account/usercenter'})
        },
        CloseTag(index){
            this.dynamicTags.splice(index,1)
        },
        AddTag(){
            this.inputVisible = true
        },
        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
            this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        change(value, render){
            this.mdData = value
        },
        fullScreen(){
            this.isfullScreen = !this.isfullScreen
        },  
        imgAdd(pos, $file){
            let formdata = new FormData();
            formdata.append('imageData', $file);
            UploadLocalImage(formdata).then(res=>{
                console.log(res.data)
                this.$message({message: '上传成功',type: 'success'})
                let url = res.data
                this.$refs.md.$img2Url(pos,url)
            })
        },
        imgDel(){

        },
        saveMavon(value,render){
            let blogData = {
                    "content":value,
                    "title":this.TitleText,
                    "keywords":this.dynamicTags,
                    "overt":this.overt
            }
            if(this.TitleText == ''){
                this.$message.error('请填写文章标题！');
            }
            else if(this.dynamicTags.length == 0){
                this.$message.error('请添加文章标签！');
            }
            else if(this.HtmlText == ''){
                this.$message.error('请填写文章内容！');
            }
            else{
                PostBlog(blogData).then(res=>{
                    this.$message({message: '发布成功，即将跳转...',type: 'success'});
                    setTimeout(()=>{
                        this.leavemark = true
                        this.$router.push({path:'/mp_blog'})
                    },2000)
                })
            }
        }
    }
}
</script>

<style  scoped>
.MHeader{
    position: relative;
    display: flex;
    align-items: center;
    line-height: 60px;
    width: 100%;
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    position: sticky;
    top: 0;
    background: #ffffff;
    padding: 0 !important;
    z-index: 1600;
}
.BackToMP{
    width: 100px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    cursor: pointer;
}
.ArticleTitle{
    flex: 1;
    height: 60px;
    position: relative;
}
.InputArea{
    position: absolute;
    left: 0;
}
.btn-Publish{
    flex: 0 0 10%;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    background-color: #fc5531;
    color: white;
    border-radius: 20px;
    cursor: pointer;
}
.UserAvatar{
    position: relative;
    display: flex;
    box-sizing: content-box;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 10%;
    height: 40px;
    border-radius: 20px;
}
.UserAvatar img{
    position: absolute;
    border-radius: 20px;
    width: 40px;
    height: 100%;
}
.UserAvatar:hover img{
  margin-top: 20px;
  transform:scale(1.5);
  border-radius: 20px;
  z-index: 30;
}
.UserAvatar:hover .UserPanel{
    display: block;
}
.UserPanel{
    position: absolute;
    top: 50px;
    display: none;
    width: 90px;
    height: 80px;
    padding: 5px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: 0px 5px 50px 0px rgb(0 0 0 / 10%);
    z-index: 20;
}

.UserCenter,.ToMP{
    padding: 5px 0px;
    font-size: 14px;
    height: 30px;
    line-height: 30px;
    cursor: pointer;
    border-radius: 4px;
}
.UserCenter:hover,.ToMP:hover{
    background-color: #dddddd;
}
.TagContent{
    text-align: left;
}
.TagTitle{
    padding: 5px 0;
    font-size: 16px;
    color: white;
}
.TagBody{
    position: relative;
    height: 40px;
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
}
.TagContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}
.Tag{
    height: 30px;
    padding: 0px 5px;
    font-size: 12px;
    line-height: 30px;
    background-color: #ecf5ff;
    color: #409eff;
    margin-left: 5px;
    border-radius: 4px;
}
.CloseMark{
    cursor: pointer;
}
.CloseMark:hover{
    border-radius: 12px;
    background-color: #409eff;
    color: white;
}
.TagAddBtn{
    margin-left: 5px;
    width: 60px;
    height: 100%;
    position: relative;
}
.btn-Add{
    position: absolute;
    top: 5px;
}
.IsPublish{
    position: absolute;
    right: 0px;
    height: 80%;
    width: 110px;
}
.PublishSelect{
    position: absolute !important;
}
.TagInput{
    height: 30px;
    margin-left: 5px;
}
/* .Tags{
    position: relative;
}
.el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }*/
.input-new-tag {
    position: absolute;
    width: 80px;
} 
.Content{
    width: 100%;
    height: 100%;
}
</style>